import React from "react";
import { css } from "glamor";
import { useCurrentUser } from "../context/current-user-context";
import {
  Link,
  useRouteMatch,
  useLocation,
} from "react-router-dom";
import ReactGA from "react-ga";
import UserMenu from "./UserMenu";

export default function () {
  const { currentUser } = useCurrentUser();
  const location = useLocation();
  const admin = currentUser.permissions && currentUser.permissions.length > 0// has permissions
  const isHome = !!useRouteMatch({ path: "/", exact: true });
  const isFaq = !!useRouteMatch({ path: "/faq", exact: true });
  const onProjects = !!useRouteMatch({ path: "/projects" });
  const onProject = !!useRouteMatch({ path: "/project" });
  const isProjects = onProject || onProjects;
  const onResources = !!useRouteMatch({ path: "/resources" });
  const onResource = useRouteMatch({ path: "/resource" });
  const isResources = onResource || onResources;
  const isAdmin = !!useRouteMatch({ path: "/admin" })

  React.useEffect(() => {
    // console.log("ga log pageview");
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
    // eslint-disable-next-line
    _gs("set", "trackLocal", true);
    // eslint-disable-next-line
    _gs("track");
  }, [location]);
  
  if (isHome) return null;

  return (
    <div {...style.container}>
      <div {...style.logoAndMenu}>
        <div>
          <Link to="/">
            <img alt="Stepup logo" src="/stepup-logo.svg" {...style.logo} />
          </Link>
        </div>
        <div {...style.menuItem}>
          <Link
            {...css(
              style.link,
              isProjects ? style.linkActive : style.linkInactive
            )}
            to="/projects"
          >
            Projects
          </Link>
        </div>
        <div {...style.menuItem}>
          <Link
            {...css(
              style.link,
              isResources ? style.linkActive : style.linkInactive
            )}
            to="/resources"
          >
            Helpers
          </Link>
        </div>
        <div {...style.menuItem}>
          <Link
            {...css(style.link, isFaq ? style.linkActive : style.linkInactive)}
            to="/faq"
          >
            FAQ
          </Link>
        </div>
        {admin && (
          <div {...style.menuItem}>
          <Link
            {...css(style.link, isAdmin ? style.linkActive : style.linkInactive)}
            to="/admin/projects"
          >
            ADMIN
          </Link>
        </div>
        )}
      </div>
      <div>
        <UserMenu />
      </div>
    </div>
  );
}
const style = {
  container: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    alignItems: "center",
  }),
  logo: css({
    width: 88,
    height: 52,
    marginRight: 30,
    "@media (max-width: 479.98px)": { width: 44, height: 26 },
  }),
  logoAndMenu: css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
  }),
  menu: css({
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  }),
  menuItem: css({
    margin: "0 20px",
    "@media (max-width: 991.98px)": {
      margin: "0 10px",
    },
  }),
  link: css({
    textTransform: "uppercase",
    fontSize: 20,
    letterSpacing: 2.5,
    "@media (max-width: 479.98px)": { fontSize: "1rem" },
  }),
  linkActive: css({
    color: "#016766",
    fontWeight: 900,
    borderBottom: "2px solid #016766",
    "&:hover": { color: "#016766" },
  }),
  linkInactive: css({
    color: "#323232",
    "&:hover": { borderBottom: "2px solid #004c4d", color: "#323232" },
  }),
};
