import React from 'react';
import { css } from "glamor"

function FAQPage() {
    return (
        <div { ...style.container}>
            <div {...style.inner}>
                <h2 className="bigger-title">About</h2>
                <h5 className="header-selected">Who is this for?</h5>
                <p {...style.p}>Step UP is designed for faculty, staff, and students at the University of Pennsylvania.  We hope Step UP provides a marketplace for project leads to match with interested helpers and foster interdisciplinary collaboration.</p>

                <h5 className="header-selected">Who developed Step UP?</h5>
                <p {...style.p}>Step UP is developed and maintained by the <a href="https://healthcareinnovation.upenn.edu/">Penn Medicine Center for Health Care Innovation.</a></p>


                <h2 className="bigger-title">Match with Projects</h2>
                <h5 className="header-selected">How does the matching process work?</h5>
                <p {...style.p}>There are filters on the site for you to select appropriate projects.  As a helper, you can reach out to the project lead once you see a project in need of a helper with your skill set.  As a project lead, you can filter for certain skills you are looking for and reach out to project helpers.</p>


                <h5 className="header-selected">Do I need to have a specific background?</h5>
                <p {...style.p}>Each project is different and will require a variety of skills.  The skills needed are listed in the project description.</p>


                <h5 className="header-selected">How much time do I need to commit to projects?</h5>
                <p {...style.p}>It depends on the project.  Helpers can indicate their availability on the “I’m eager to help” form and be matched appropriately with a project.</p>


                <h5 className="header-selected">Can I reach out to the project lead/professor directly?</h5>
                <p {...style.p}>Yes!  We encourage this. Think of this as you would a LinkedIn job posting.</p>

                <h5 className="header-selected">What are the next steps after I reach out?</h5>
                <p {...style.p}>Please expect to hear back from the project leads within 48-72 hours.  Project leads may be on clinical duties.</p>

                <h2 className="bigger-title">Earn Credit</h2>
                <h5 className="header-selected">Are these projects approved by my school’s leadership?</h5>
                <p {...style.p}>During the COVID-19 pandemic, projects on Step UP are reviewed by a Penn School of Medicine administrator before posting to ensure that they meet the following three safety criteria: (1) no face-to-face patient contact, (2) faculty supervision, and (3) remote work or transport via personal car, bike, scooter, or walking.</p>

                <h5 className="header-selected">Will I get school credit for helping?</h5>
                <p {...style.p}>For PSOM, the work that you do as part of these opportunities is generally outside of normal course work but there are many ways that we define “credit.”  For some students, this work could evolve into a scholarly pursuit project and would thereby give you credit.  For other opportunities, you may be an author on an abstract and/or a peer-reviewed research paper, which is also a measure of credit.  And for others, simply helping the school, University, and community is credit enough!  If you have individual questions about earning credit for this work, please reach out to Dr. Christian and Dr. Kogan.<br /><br />For all other schools, please contact your school’s administration to determine if you can receive credit for the project.
                </p>

                <h2 className="bigger-title">Learn More</h2>
                <h5 className="header-selected">Where can I find more projects?</h5>
                <p {...style.p}>Additional projects related to addressing emergent unmet needs related to COVID-19 with technology solutions can be found on the Penn Health-Tech <a href="https://healthtech.upenn.edu/covid-19">website</a>.</p>

                <h5 className="header-selected">Have a question?  We are here to help.</h5>
                <p {...style.p}>Click on the chat icon in the bottom right corner of the screen for help. A member of the team will respond in the chat box in a couple of minutes or via email within a few hours.</p>

            </div>
            <div {...style.spacer} />
        </div>
    );
}

const style = {
    container: css({
        display: "flex",
        flexDirection: "row",
    }),
    inner: css({
        margin: '50px 25px',
        flex: 1
    }),
    spacer: css({
        display: 'none',
        flex: 1,
        "@media (min-width: 990px)": { display: 'block' }
    }),
    p: css({
        marginBottom: '60px'
    })
}

export default FAQPage;
