import React from "react";
import { Switch, Route, Link, useRouteMatch } from "react-router-dom";
import { css } from "glamor";

import AdminProjects from "../components/AdminProjects";
import AdminResources from "../components/AdminHelpers";
import AdminUsers from "../components/AdminUsers";
import AdminApplications from "../components/AdminApplications";
import { useCurrentUser } from "../context/current-user-context";

function AdminPage() {
  const { currentUser } = useCurrentUser();
  const permissions =
    currentUser.permissions && currentUser.permissions.length ? currentUser.permissions : [];
  const onProjectsTab = useRouteMatch({ path: "/admin/projects" });
  const onResourcesTab = useRouteMatch({ path: "/admin/resources" });
  const onUsersTab = useRouteMatch({ path: "/admin/users" });
  const onApplicationsTab = useRouteMatch({ path: "/admin/applications" });
  const isAdmin = permissions.includes("admin");
  return (
    <div {...styles.container}>
      <div {...styles.tabs}>
        {(isAdmin || permissions.includes("projects")) && (
          <Link {...css(styles.tab, onProjectsTab && styles.activeTab)} to="/admin/projects">
            Projects
          </Link>
        )}
        {(isAdmin || permissions.includes("resources")) && (
          <Link {...css(styles.tab, onResourcesTab && styles.activeTab)} to="/admin/resources">
            Resources
          </Link>
        )}
        {(isAdmin || permissions.includes("users")) && (
          <Link {...css(styles.tab, onUsersTab && styles.activeTab)} to="/admin/users">
            Users
          </Link>
        )}
        {(isAdmin || permissions.includes("applications")) && (
          <Link {...css(styles.tab, onApplicationsTab && styles.activeTab)} to="/admin/applications">
            Applications
          </Link>
        )}
      </div>
      <Switch>
        <Route exact path="/admin/projects" component={AdminProjects} />
        <Route exact path="/admin/resources" component={AdminResources} />
        <Route exact path="/admin/users" component={AdminUsers} />
        <Route exact path="/admin/applications" component={AdminApplications} />
      </Switch>
    </div>
  );
}

const styles = {
  container: css({
    width: "100%",
    margin: "30px auto",
  }),
  tabs: css({
    display: "flex",
    width: "280px",
  }),
  tab: css({
    flex: 1,
    border: "1px solid #969696",
    padding: "0.35em 1em",
    color: "#016766",
    ":hover": {
      color: "#004c4d",
      backgroundColor: "#B3D9DA",
    },
  }),
  activeTab: css({
    backgroundColor: "#016766",
    color: "white",
    ":hover": {
      backgroundColor: "#016766",
      color: "white",
    },
  }),
};

export default AdminPage;
