import React, { useEffect, useState } from "react";
import _ from "lodash";
import ProjectForm from "../components/ProjectForm";
import { useToasts } from "react-toast-notifications";
import { useParams } from "react-router-dom";

import feathers from "../etc/feathers-client";

export default function ProjectFormPage() {
  const { addToast } = useToasts();
  const [project, setProject] = useState();
  let { projectId } = useParams();
  async function loadProject() {
    try {
      setProject(
        projectId ? await feathers.service("projects").get(projectId) : {}
      );
    } catch (error) {
      addToast(_.get(error, "response.data.message", error.message), {
        appearance: "error",
      });
    }
  }
  useEffect(() => {
    loadProject();
  }, [projectId]);

  if (!project) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <ProjectForm project={project} />
    </div>
  );
}
