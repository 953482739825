import React from "react";
import "core-js";
import { css } from "glamor";
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";

import { CurrentUserContext } from "./context/current-user-context";
// import ReactGA from "react-ga";
import HomePage from "./pages/home-page";
import ResourceListPage from "./pages/resource-list-page";
import ResourceFormPage from "./pages/resource-form-page";
import ProjectListPage from "./pages/project-list-page";
import ProjectFormPage from "./pages/project-form-page";
import ProjectPage from "./pages/project-page";
import ResourcePage from "./pages/resource-page";
import NotFoundPage from "./pages/not-found-page";
import FAQPage from "./pages/faq-page";
import AdminPage from "./pages/admin-page";

import NavBar from "./components/NavBar";
// import Intercom from "react-intercom";
import feathers from "./etc/feathers-client";

const App = () => {
  // const isHome = !!useRouteMatch({ path: "/", exact: true });
  const [state, dispatch] = React.useContext(CurrentUserContext);
  // const location = useLocation();

  React.useEffect(() => {
    (async function loadCurrentUser() {
      try {
        const currentUser = await feathers.service("users").get(1);

        dispatch({
          type: "FETCH_CURRENT_USER",
          payload: currentUser,
        });
        if (currentUser && currentUser.name) {
          const firstName = currentUser.name.split(" ").slice(0, -1).join(" ");
          const lastName = currentUser.name.split(" ").slice(-1).join(" ");
          //eslint-disable-next-line
          _gs("identify", {
            email: `${currentUser.pennName}@upenn.edu`,
            first_name: firstName,
            last_name: lastName,
            custom: { pennId: currentUser.pennId },
          });
        }
      } catch (e) {
        console.log("error", e);
      }
    })()
  }, [dispatch]);

  // React.useEffect(() => {
  //   console.log("pageview");
  //   ReactGA.pageview(window.location.pathname);
  // }, [dispatch]);

  // console.log({ currentUser: state.currentUser });
  // const intercomUser =
  //   state.currentUser && state.currentUser.pennId
  //     ? {
  //         user_id: state.currentUser._id,
  //         email: `${state.currentUser.pennName}@upenn.edu`,
  //         name: state.currentUser.name,
  //         pennId: state.currentUser.pennId,
  //       }
  //     : {};

  return (
    <Router>
      <div {...style.container}>
        {/* <Intercom appID="s6qzmldj" {...intercomUser} /> */}
        <NavBar />
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/faq" component={FAQPage} />
          <Route exact path="/projects" component={ProjectListPage} />
          <Route exact path="/resources" component={ResourceListPage} />
          <Route path="/project/:projectId" component={ProjectPage} />
          <Route path="/resource/:resourceId" component={ResourcePage} />
          <Route path="/admin" component={AdminPage} />
          {/* <Route
        path="/comments-for-project/:projectId"
        component={CommentsForProjectPage}
      /> */}
          <Route path="/resources/new" component={ResourceFormPage} />
          <Route
            path="/resources/edit/:resourceId"
            component={ResourceFormPage}
          />
          <Route path="/projects/new" component={ProjectFormPage} />
          <Route path="/projects/edit/:projectId" component={ProjectFormPage} />
          <Route component={NotFoundPage} />
        </Switch>
      </div>
    </Router>
  );
};

export default App;

const style = {
  container: css({
    margin: "20px auto",
    // "@media only screen and (min-width: 1200px)": {
    //   width: 1127
    // },
    "@media only screen and (min-width: 1300px)": {
      width: 1227,
    },
    "@media only screen and (max-width: 1299.98px) and (min-width: 1092px)": {
      width: 1093,
    },
    "@media only screen and (max-width: 1199.98px) and (min-width: 992px)": {
      width: 993,
    },
    "@media only screen and (max-width: 991.98px) and (min-width: 768px)": {
      width: 723,
    },
    "@media only screen and (max-width: 767.98px)": {
      width: "auto",
      marginLeft: "1em",
      marginRight: "1em",
    },
  }),
};
